.product--card{

    width: 415px;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px rgba(0, 7, 73, 0.25);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    transition: all .2s linear;

    &:hover{
        box-shadow: none;
    }

    .card{

        &--link{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 5;
        }

        &--container{
            margin-left: 36px;
            margin-right: 31px;
            padding-top: 10px;
            padding-bottom: 26px;

            @media(max-width: 1920px){
                margin-left: calcFluidAll(5, 36);
                margin-right: calcFluidAll(5, 31);
            }
        }

        &--image-container{
            position: relative;

            .sale--label{
                position: absolute;
                bottom: 0;
                left: 35px;

                .label--title{
                    padding: 11.5px 13px 11.5px 13px;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 25px;
                    color: #FFFFFF;
                }

                background: #F91658;
                border-radius: 12px;
            }

        }

        &--image{
            width: 100%;
            height: 335px;
            object-fit: contain;

            @media(max-width: 1920px){
                height: calcFluidAll(82, 335);
            }

        }

        &--information{

            margin-bottom: 40px;

            .info{

                &--title{
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 26px;
                    color: #1E1E1E;
                    margin-bottom: 5px;

                    @media(max-width: 1920px){
                        font-size: calcFluidAll(11, 22);
                        line-height: calcFluidAll(13, 26);
                    }
                }

                &--code{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #6A6A6A;

                    @media(max-width: 1920px){
                        font-size: calcFluidAll(10, 16);
                        line-height: calcFluidAll(12, 19);
                    }
                }

                &--price{
                    font-weight: 400;
                    font-size: 34px;
                    line-height: 41px;
                    color: #3D3D3D;

                    @media(max-width: 1920px){
                        font-size: calcFluidAll(14.5, 34);
                        line-height: calcFluidAll(17, 41);
                    }

                    &.price--now_old{

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;

                        .price--old{
                            position: relative;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 26px;
                            color: rgba(61, 61, 61, 0.5);         

                            @media(max-width: 1920px){
                                font-size: calcFluidAll(18, 26);
                            }

                            &::before{
                                position: absolute;
                                content: "";
                                left: 0;
                                right: 0;
                                border: solid;
                                border-width: 0;
                                border-color: #f91658;
                                border-bottom-width: 2px;
                                transform: rotate(-11deg);
                                bottom: 13px;
                            }
                        }
                    }
                }

                &--title_code{
                    height: 60px;
                    margin-bottom: 45px;
                    @media (max-width: 1920px) {
                        margin-bottom: calcFluid(10, 45, 320, 1920);
                    }
                }

            }

        }

        &--inCart{
            position: relative;
            z-index: 6;
            text-align: center;
            margin-bottom: 15px;
            @media(max-width: 1920px){
                font-size: calcFluidAll(10, 21);
                line-height: calcFluidAll(14, 25);
                
                padding-top: calcFluidAll(6, 18);
                padding-bottom: calcFluidAll(6, 18);
                padding-left: calcFluidAll(6, 54);
                padding-right: calcFluidAll(6, 54);

                margin-bottom: calcFluidAll(5, 15);

            }
        }

        &--oneClick{
            position: relative;
            z-index: 6;
            text-align: center;
            @media(max-width: 1920px){
                font-size: calcFluidAll(10, 21);
                line-height: calcFluidAll(14, 25);

                padding-top: calcFluidAll(6, 18);
                padding-bottom: calcFluidAll(6, 18);
                padding-left: calcFluidAll(6, 54);
                padding-right: calcFluidAll(6, 54);
            }
        }

    }

    @media (max-width: 920px) {
        width: 100%;
        min-height: auto;

        .card--information{
            text-align: center;
            margin-bottom: 20px;
        }

    }

}