.products--slider{

    .slider--header{
        font-weight: 700;
        font-size: 62px;
        color: #1E1E1E;
        margin-bottom: 40px;
        font-family: 'PT Sans Narrow';
        @media(max-width: 1920px){
            font-size: calcFluidAll(22, 62);
            margin-bottom: calcFluidAll(20, 40);
        }
    }

    .slider--block{
        margin-bottom: 60px;
        padding: 10px;

        padding-left: 15px;
        padding-right: 15px;
        
        @media(max-width: 950px){
            padding-left: 0;
            padding-right: 0;

            .slider--list{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 10px;
                row-gap: 20px;
            }
        }
    }

    .slider--list{
        .list--item{
            .product--card{
                width: auto;
                height: auto;
            }
        }
    }

    .slider--showMore{
        display: block;
        width: fit-content;
        text-align: center;
        margin: auto;
        min-width: 278px;
    }

    .slider--container{
        position: relative;
        overflow: hidden;
        padding-left: 30px;
        padding-right: 30px;
        @media(max-width: 1920px){
            padding-left: calcFluidAll(0, 30);
            padding-right: calcFluidAll(0, 30);
        }

        @media(max-width: 950px){
            overflow: visible;
        }
    }

    .slider--arrows{
        $offset_position: -65px;

        position: absolute;
        z-index: 5;
        top: 0;
        left: $offset_position;
        right: $offset_position;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: none;

        .arrow--item{

            width: 35px;
            height: 35px;
            pointer-events: all;
            cursor: pointer;

            svg{
                width: 100%;
                height: 100%;
                stroke: #1E1E1E;
                fill: none;
            }

            &.arrow--next{
                transform: rotate(180deg);
            }

        }

        @media (max-width: 1900px) {
            $offset_position: -45px;
            left: $offset_position;
            right: $offset_position;
        }

        @media (max-width: 1820px) {
            display: none;
        }

    }

    @media(max-width: 600px){

        .slider--block{
            margin-bottom: 30px;
        }

        .slider--showMore{
            width: 100%;
        }

    }

}