.category--card{
    position: relative;
    display: block;
    border-radius: 44px;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .card--image-container{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        .card--image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            transition: all .2s linear;
        }
    }

    .card--title{

        position: absolute;
        bottom: 30px;
        left: 26px;
        right: 26px;

        font-weight: 400;
        font-size: 24px;
        @media (max-width: 1960px){
            font-size: calcFluid(16, 24, 320, 1960);
        }
        line-height: 29px;
        text-align: center;
        color: #EDEDED;
    }

    &:hover{

        .card--image-container{
            &::after{
                background: rgba(0, 0, 0, 0.35);
                transition: all .2s linear;
            }
        }
    }
}