@import 'vendors/swiper.min';


@import 'base/app';
@import 'components/category-card';
@import 'components/countdown';
@import 'components/product-card';
@import 'components/product-slider';
@import 'components/content/seo';
@import 'components/content/text';



.section{

    &--slider_categories{

        margin-bottom: 120px;
        @media(max-width: 1920px){
            margin-bottom: calcFluid(20, 120, 320, 1920);
        }

        .slider--block{

            position: relative;
            height: 640px;

            @media(max-width: 1920px){
                height: calcFluidDesktop(400, 640);
            }
            @media (max-width: 1400px) {
                height: calcFluidMobile(320, 400);
                min-height: 320px;
            }
            @media (max-width: 512px) {
                height: calcFluidMobile(320, 400);
                max-height: 180px;
                min-height: 100px;
                // @media (max-width: 412px) {
                //     min-height: 140px;
                // }
            }


            overflow: hidden;

            .block--arrows{
                z-index: 5;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                left: 55px;
                top: 0;
                right: 55px;
                bottom: 0;
                pointer-events: none;

                .arrow--item{

                    width: 35px;
                    height: 35px;
                    pointer-events: all;
                    cursor: pointer;

                    svg{
                        width: 100%;
                        height: 100%;
                        stroke: #D9D9D9;
                        fill: none;
                    }

                    &.arrow--next{
                        transform: rotate(180deg);
                    }

                }

                @media (max-width: 750px) {
                    left: 10px;
                    right: 10px;
                }

            }

            .block--list{

                height: 100%;

                .list--item{

                    height: 100%;

                    position: relative;

                    .item--container{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .item--information{
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .item--title{
                            font-weight: 700;
                            font-size: 76px;
                            line-height: 115%;
                            text-align: center;
                            color: #FFFFFF;
                            margin-bottom: 26px;
                        }

                        .item--description{
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 38px;
                            color: #FFFFFF;
                            margin-bottom: 44px;
                            text-align: center;
                        }

                        .item--button{
                            padding-left: 80px;
                            padding-right: 80px;
                            width: fit-content;
                            text-align: center;
                        }

                        @media (max-width: 1279px) {
                            .item--title{
                                font-weight: 700;
                                font-size: 30px;
                                line-height: 39px;
                                text-align: center;
                            }

                            .item--description{
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                text-align: center;
                                color: #FFFFFF;
                            }

                            .item--button{
                                width: 100%;
                                max-width: 278px;
                                padding: 14px 60px 14px 60px;
                                line-height: 22px;
                                height: 52px;
                            }
                        }

                    }

                    .item--image-container{

                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        z-index: -1;

                        &::after{
                            /*content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: rgba(0, 0, 0, 0.5);*/
                        }

                        .item--image{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;

                            @media (max-width: 512px) {
                                height: inherit;
                            }
                        }

                    }

                }

            }

            .block--pagination{

                position: absolute;
                bottom: 130px;
                z-index: 1;
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                justify-content: center;

                @media (max-width: 512px) {
                    display: none;
                }

                .swiper-pagination-bullet{
                    background-color: #FFFFFF;
                }

                .swiper-pagination-bullet-active{
                    position: relative;
                    &::after{

                        $offset: -6px;

                        content: "";
                        position: absolute;
                        top: $offset;
                        left: $offset;
                        right: $offset;
                        bottom: $offset;
                        background: none;
                        border: 1px solid #FFFFFF;
                        border-radius: 100%;
                    }
                }

                @media (max-width: 1400px) {
                    bottom: 75px;
                }

                @media (max-width: 900px) {
                    gap: 10px;
                }
            }


        }

        .categories--block{

            position: relative;
            z-index: 2;

            .block--list{

                margin-top: -110px;

                padding-top: 45px;
                background: #FFFFFF;
                border-radius: 39px;
                height: 440px;
                display: flex;
                justify-content: center;
                gap: 45px;

                .list--item{
                    width: 290px;
                    height: 100%;


                    &.item--showMore{

                        display: none;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        .item--arrow{
                            width: 30px;
                            stroke: #0056A5;
                            fill: #0056A5;

                            svg{
                                width: 100%;
                                height: 100%;
                            }
                            @media(max-width: 1920px){
                                width: calcFluidAll(30, 70);
                            }
                        }
                        .item--text{
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 16px;
                            text-align: center;
                            color: #0056A5;
                            position: absolute;
                            bottom: 20px;
                            left: 0;
                            right: 0;
                            @media(max-width: 1920px){
                                font-size: calcFluid(12, 24, 320, 1960);
                            }
                        }
                    }

                }

            }

            @media (max-width: 1749px) {
                .container{
                    padding: 0;
                }

                .block--list{
                    gap: 20px;
                    .list--item{
                        width: 250px;
                    }
                }
            }

            @media (max-width: 1400px){
                .block--list{
                    display: grid;
                    gap: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                    grid-template-columns: repeat(3, 1fr);
                    margin-top: -40px;
                    height: auto;
                    .list--item{
                        width: auto;
                        height: 320px;

                        &.item--showMore{
                            display: flex;
                        }
                    }
                }
            }

            @media (max-width: 600px) {
   
                .block--list{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;
                    margin-top: 0;
                    padding: 15px;
                    height: auto;

                    .list--item{
                        width: 100%;
                        height: 230px;

                        .category--card{
                            border-radius: 20px;

                            .card--title{
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                text-align: center;                                
                            }

                        }
                    }

                }
            }

        }

    }

    &--popular{

        @media(max-width: 950px){
            .slider--block{
                overflow: visible;
                padding-left: 0;
                padding-right: 0;

                .slider--list{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 10px;
                    row-gap: 20px;
                }
            }
        }

    }

}