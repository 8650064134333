.content--text{
    h1{
        font-weight: 700;
        font-size: 65;
        line-height: 115%;
        color: #1E1E1E;    
        margin-bottom: 26px;
        font-family: 'PT Sans Narrow';
        @media(max-width: 1920px){
            font-size: calcFluidAll(40, 65);
        }
    }

    h2{
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        color: #1E1E1E;
        margin-bottom: 22px;
        font-family: 'PT Sans Narrow';
        @media(max-width: 1920px){
            font-size: calcFluidAll(30, 50);
            line-height: calcFluidAll(35, 60);
        }
    }

    h3{
        font-weight: 700;
        font-size: 40px;
        line-height: 125%;
        color: #1E1E1E;
        margin-bottom: 22px;
        font-family: 'PT Sans Narrow';
        @media(max-width: 1920px){
            font-size: calcFluidAll(20, 40);
        }
    }

    p{
        font-weight: 400;
        font-size: 21px;
        line-height: 145%;
        color: #1E1E1E;
        margin-bottom: 54px;
        @media(max-width: 1920px){
            font-size: calcFluidAll(16, 21);
        }
    }

    li{
        font-size: 21px;
        @media (max-width: 1920px) {
            font-size: calcFluidAll(16, 21);
        }
    }

    ol, ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
        
    ol li {
        list-style-type: decimal;
        list-style-position: inside;
    }

    ul li {
        list-style-type: disc;
        list-style-position: inside;

        &::marker{
            color: #0056a5;
        }
    }
}



/*
.content--text{
    h1{
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: $text-clr;
        margin: 0 0 20px;
        @include _992{
            font-size: 28px;
            line-height: 34px;
            margin: 0 0 15px;
        }
    }
    h2{
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
        color: $text-clr;
        margin: 0 0 16px;
        &:not(:first-child){
            margin: 38px 0 14px;
            @include _992{
                margin: 20px 0 15px;
            }
        }
        @include _992{
            font-size: 24px;
            line-height: 28px; 
        }
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: $text-clr;
        margin: 40px 0 5px;
        @include _992{
            font-size: 18px;
            line-height: 22px;
            margin: 20px 0 10px;
        }
    }
    h4{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $text-clr;
        margin: 35px 0 10px;
        @include _992{
            font-size: 18px;
            line-height: 22px;
            margin: 20px 0 10px;
        }
    }
    p, a, li{
        font-size: 16px;
        line-height: 24px;
        color: $text-clr;
        @include _992{
            font-size: 14px;
            line-height: 18px;
        }
    }
    p{
        margin: 0 0 8px;
        @include _992{
            margin: 0 0 10px;
        }
    }
    a{
        color: $main-clr;
        transition: color $tr-time;
        border-bottom: 1px solid $main-clr;
        &:hover{
            color: $text-clr;
            border-bottom: 1px solid $text-clr;
        }
        &:visited{
            color: #AA85D7;
            border-bottom: 1px solid #AA85D7;
        }
    }
    img{
        max-width: 100%;
        width: 100%;
        margin: 35px 0;
        @include _992{
            margin: 20px 0;
        }
    }

    ol, ul{
        padding-left: 15px;
        margin: 8px 0;
        li{
            padding-left: 5px;
            &:not(:last-child){
                margin: 0 0 8px;
            }
        }
    }

    ol{
        li{
            list-style-type: decimal;
        }
    }
    ul{
        li{
            list-style-type: none;
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                background-color: $main-clr;
                border-radius: 50%;
                position: absolute;
                top: calc(50% - 4px);
                left: -10px;
            }
        }
    }
    .table-wrap{
        max-width: 100%;
        overflow: scroll;
    }
    table{
        margin: 35px 0 0;
        border: none;
        width: 100%;
        border-collapse: collapse;
        @include _992{
            margin: 20px 0 0;
        }
        > caption{
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            color: $text-clr;
            border-bottom: 2px solid $main-clr;
            text-align: left;
            padding: 0 0 18px;
            @include _992{
                font-size: 24px;
                line-height: 28px; 
            }
        }

        thead{
            border-bottom: 2px solid $main-clr;
        }

        tbody{
            border: none;
        }

        th, td{
            padding: 20px;
            font-size: 16px;
            line-height: 22px;
            color: $text-clr;
            text-align: left;
            border: 1px solid #C9CED8;
            @include _992{
                font-size: 14px;
                line-height: 18px;
                padding: 10px;
            }
        }

        th{
            font-weight: 700;
        }
    }
}*/